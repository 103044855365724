let $body = $('body');
let json;
let config = {};
let progression = {};
let percentageProgression = 0;
let exercices = {};
let lengthSlideIntro = 5;
let sessionTime = 0;

let color_module = [];
color_module[1] = '#A2185B';
color_module[2] = '#EA5B0C';
color_module[3] = '#0062A7';
color_module[4] = '#A1AE14';
color_module[5] = '#7F7F79';

/**
 * Recupération des données de l'utilisateur
 */
let getData = function (dfd) {
    let url = '/index.php';
    $.ajax({
        type: "GET",
        url: url,
        dataType: 'json',
        data: {'getinfo': true}
    }).done(function (data) {
        config.connect = false;
        if (data && !data.error) {
            config.connect = true;

            if (data.total_time && data.total_time !== '') {
                config.totalTime = data.total_time;
                timer(parseInt(config.totalTime));
            } else {
                timer(0);
            }

            if (data.user_id && data.user_id !== '') config.user_id = data.user_id;
            if (data.location && data.location !== '') config.location = JSON.parse(data.location);
            (data.progression && data.progression !== '') ? progression = config.progression = JSON.parse(data.progression) : progression;
            (data.percentage_progression && data.percentage_progression !== '') ? percentageProgression = config.percentageProgression = JSON.parse(data.percentage_progression) : percentageProgression;
            if (data.exercices && data.exercices !== '') exercices = config.exercices = JSON.parse(data.exercices);

            config.numberConnection = data.number_connection && data.number_connection !== '' ? parseInt(data.number_connection) : 0;
            config.numberConnection++;
        }
        return dfd.resolve();
    });
};

/**
 * getLocation
 * @param {object} dfd
 * @returns {object}
 */
let getLocation = function (dfd) {
    if (config.location) {
        if (config.location[0] !== -1) config.type = 'I'; // Quiz intro
        if (config.location[1] !== -1) config.type = 'M'; // Listes des modules
        if (config.location[2] !== -1) config.type = 'C'; // Listes des chapitres
        if (config.location[3] !== -1) config.type = 'S'; // Ecran
    }
    return dfd.resolve();
};

/**
 * updateLocation
 * @param int $i quiz intro
 * @param int $m module
 * @param int $c chapter
 * @param int $s screen
 */
let updateLocation = function (quiz = 0, module = -1, chapter = -1, screen = -1) {
    $body.find('#mask').show();
    config.location = [parseInt(quiz), parseInt(module), parseInt(chapter), parseInt(screen)];
    saveToBDD();
};

/**
 * saveProgression
 * Sauvegarde de la progression
 * @param int module
 * @param int chapter
 * @param int screen
 */
let saveProgression = function (module = false, chapter = false, screen = false, dfd = false, intro = false) {
    // if (!module || !chapter || !screen) return false;

    if (json && !$.isEmptyObject(progression)) {
        save();
    }

    if (json && $.isEmptyObject(progression)) {
        //Intro
        progression[0] = {};
        progression[0][0] = {};
        progression[0][0].screens = {};
        for (let i = 2; i < 6; i++) {
            progression[0][0].screens[i] = {};
            progression[0][0].screens[i].status = false;
            if (i === 5) save();
        }

        // Modules
        $.each(json, function (kModule, vModule) {
            progression[kModule] = {};

            $.each(vModule.chapters, function (kChapter, vChapter) {
                progression[kModule][kChapter] = {};
                progression[kModule][kChapter].screens = {};
                progression[kModule][kChapter].sections = {};

                $.each(vChapter.screen, function (kScreen, vScreen) {
                    progression[kModule][kChapter].screens[kScreen] = {};
                    progression[kModule][kChapter].screens[kScreen].status = false;
                    progression[kModule][kChapter].screens[kScreen].section = vScreen.section;
                    if (!progression[kModule][kChapter].sections[vScreen.section]) {
                        progression[kModule][kChapter].sections[vScreen.section] = {};
                        progression[kModule][kChapter].sections[vScreen.section].nbr = 0;
                    }
                    progression[kModule][kChapter].sections[vScreen.section].nbr++;

                    if (kModule == Object.keys(json).length
                        && kChapter == Object.keys(vModule.chapters).length
                        && kScreen == Object.keys(vChapter.screen).length) {
                        save();
                    }
                });
            });
        });

    }

    function save() {
        if (progression[module][chapter].screens[screen].status !== true) {
            progression[module][chapter].screens[screen].status = true;

            if (!intro) {
                let section = progression[module][chapter].screens[screen].section;
                progression[module][chapter].sections[section].nbrDone = 0;

                for (let i = 1; i <= Object.keys(progression[module][chapter].screens).length; i++) {
                    let vScreen = progression[module][chapter].screens[i];
                    if (vScreen.status === true && vScreen.section === section) {
                        progression[module][chapter].sections[section].nbrDone++;
                    }
                }
            }
        }

        if (dfd) return dfd.resolve();
    }
};

/**
 * Sauvegarde en base
 */
let saveToBDD = function () {
    if (!config.user_id) return false;

    // Calcul du pourcentage de complétion
    let nbItems = 0;
    let nbItemsConsulted = 1;
    $.each(progression, function (kModule, vModule) {
        $.each(vModule, function (kChapter, vChapter) {
            $.each(vChapter.screens, function (kScreen, vScreen) {
                nbItems++;
                if (vScreen.status) nbItemsConsulted++;
                if (kModule == Object.keys(progression).length - 1
                    && kChapter == Object.keys(vModule).length
                    && kScreen == Object.keys(vChapter.screens).length) {
                    percentageProgression = nbItemsConsulted * 100 / nbItems;
                    percentageProgression = percentageProgression.toFixed(2);
                    config.percentageProgression = percentageProgression;
                    config.progression = progression;
                    sendData();
                }
            });
        });
    });

    function sendData() {
        let url = '/?set';
        let data = {
            'location': (config.location) ? JSON.stringify(config.location) : '[0, -1, -1, -1]',
            'progression': (config.progression) ? JSON.stringify(config.progression) : null,
            'exercices': (exercices) ? JSON.stringify(exercices) : null,
            'user_id': (config.user_id) ? config.user_id : '',
            'session_time': (sessionTime) ? sessionTime : false,
            'total_time': (config.totalTime) ? config.totalTime : false,
            'number_connection': (config.numberConnection) ? config.numberConnection : 0,
            'percentage_progression': (config.percentageProgression) ? config.percentageProgression : 0
        };
        $.ajax({
            type: "POST",
            url: url,
            data: data,
            dataType: 'json'
        }).done(function (data) {
            if (data) {
                console.log('success');
            }
        });
    }

    setTimeout(function () {
        $body.find('#mask').hide();
    }, 500);
};

/**
 * genére le certificat
 */
let generateCertificat = function () {
    if (!config.user_id) return false;
    let url = '/?certificat&user_id=' + config.user_id;
    window.open(url);
};

/**
 * genére le certificat
 */
let timer = function (time) {
    config.totalTime = time;
    setInterval(function () {
        sessionTime++;
        config.totalTime++;
    }, 1000);
};

/**
 * Suppression des balises HTML
 */
let removeTagHtml = function (string) {
    string = string.replaceAll('<br />', ' ');
    string = string.replaceAll('<br/>', ' ');
    string = string.replaceAll('<br>', ' ');

    let reg = new RegExp("<.[^>]*>", "gi");
    return string.replace(reg, "");
};
/**
 * Raple All
 */
String.prototype.replaceAll = function (search, replacement) {
    let target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};


/**
 * updateQuiz
 */
// let updateQuiz = function () {
//
//     let inputQuiz = $inputQuiz.val();
//     if (inputQuiz && inputQuiz !== '') {
//         inputQuiz = JSON.parse(inputQuiz);
//         let inputQuizLength = Object.keys(inputQuiz).length;
//         if (inputQuizLength !== 0) {
//             $.each(inputQuiz[0], function (k, v) {
//                 $itemsQuiz.eq(--k).find('#connection input[type="radio"][value="' + v + '"]').prop('checked', true);
//             });
//         }
//     }
//
//     let quiz = {"0": {}};
//     $body.on('change', 'input[type="radio"]', function () {
//         let name = $(this).attr('name');
//         quiz[0][name] = $(this).val();
//         $inputQuiz.val(JSON.stringify(quiz));
//     });
// };

$(document).ready(function () {
    let dfdGetData = $.Deferred();
    getData(dfdGetData);
    dfdGetData.then(function () {
        let dfdIndex = $.Deferred();
        index.init(dfdIndex);
        dfdIndex.then(function () {
            navigation.init();
        });
    });
});